<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }}) </small>
        {{ id ? data.name : "Новый пользователь" }}
      </template>
      <a-loader v-if="!loaded" />
      <v-row v-else class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], model)"
              :errors="errors"
              :config="{ dense: true }"
              @validate="validate($event)"
            />
          </div>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <a-btn-add @click="configDialpgShow = true" v-if="getAccess('user.config')" title="Настройки" icon="fas fa-user-cog" />
        <v-spacer />
        <a-btn-save @click="submit()" v-if="data.role <= $root.profile.role" />
        <a-btn-delete v-if="id && getAccess('user.delete')" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";
export default {
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  components: {
    ConfigDialog: () => import("./userConfigDialog"),
  },
  props: {
    value: Boolean,
    id: Number,
    api: String,
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.users,
      removeDialogShow: false,
      configDialpgShow: false,
      loaded: false,
    };
  },
  created() {},
  watch: {
    value() {
      if (this.value) this.updateData(this.id);
    },
  },
  computed: {
    model() {
      let config = this.m.config;
      console.log(config, this.m);
      let model = this.calcModelConfig(config);
      if (false)
        model.forEach((el) => {
          if (el.name == "role") {
            el.options = el.options.filter((r) => r.value <= this.$root.profile.role);
          }
        });
      return model;
    },
  },
  methods: {
    getModel(t) {
      let config = this.m.config;
      console.log(config, this.m);
      let model = this.calcModelConfig(config);
      model.forEach((el) => {
        if (el.name == "role") {
          el.options = el.options.filter((r) => r.value <= this.$root.profile.role);
        }
      });
      this.model = model;
    },
    afterFetchData(r) {
      // this.calcModel(this.data?.type);
      //   this.getModel();
      this.loaded = true;
    },
    submit1() {
      console.log("submit", this.data);
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      if (status) {
        this.$emit("input");
        this.$emit("setUser", data);
      }
    },
  },
};
</script>
